import {merge} from 'lodash';
import ym from 'react-yandex-metrika';

import {EnvTypes} from 'reducers/app/types';
import store from 'reduxStore';
import envSelector from 'selectors/app/config/envSelector';

export type EventType =
    | 'timepicker_keyboard'
    | 'timepicker_ui'
    | 'language'
    | 'onboarding_shown'
    | 'onboarding_complete'
    | 'onboarding_skip'
    | 'reports_operator_statistic_load'
    | 'predictions_transition_to_creating_forecast'
    | 'predictions_uploading_new_forecast'
    | 'predictions_application_of_correction_factors'
    | 'predictions_calculation_of_operators'
    | 'balancing_starting'
    | 'balancing_manual_finish'
    | 'fixed_breaks_fix'
    | 'select_favorite_filter'
    | 'remove_favorite_filter'
    | 'save_favorite_filter';

type EventData = {
    timepicker_keyboard: Record<string, unknown>;
    timepicker_ui: Record<string, unknown>;
    language: Record<string, unknown>;
    onboarding_shown: Record<string, unknown>;
    onboarding_complete: Record<string, unknown>;
    onboarding_skip: Record<string, unknown>;
    reports_operator_statistic_load: Record<string, unknown>;
    predictions_transition_to_creating_forecast: Record<string, unknown>;
    predictions_uploading_new_forecast: Record<string, unknown>;
    predictions_application_of_correction_factors: Record<string, unknown>;
    predictions_calculation_of_operators: Record<string, unknown>;
    balancing_starting: Record<string, unknown>;
    balancing_manual_finish: Record<string, unknown>;
    fixed_breaks_fix: Record<string, unknown>;
    select_favorite_filter: Record<string, unknown>;
    remove_favorite_filter: Record<string, unknown>;
    save_favorite_filter: Record<string, unknown>;
};

type MetrikaParams = {
    [key: string]: string;
};

export type MetrikaLogs<T extends EventData> = {
    [K in keyof T]: Partial<T[K]>
};

export type MetrikaCookies = {
    yandexuid?: string;
};

const DEFAULT_LOGS: MetrikaLogs<EventData> = {
    'timepicker_keyboard': {},
    'timepicker_ui': {},
    'language': {},
    'onboarding_shown': {},
    'onboarding_complete': {},
    'onboarding_skip': {},
    'reports_operator_statistic_load': {},
    'predictions_transition_to_creating_forecast': {},
    'predictions_uploading_new_forecast': {},
    'predictions_application_of_correction_factors': {},
    'predictions_calculation_of_operators': {},
    'balancing_starting': {},
    'balancing_manual_finish': {},
    'fixed_breaks_fix': {},
    'select_favorite_filter': {},
    'remove_favorite_filter': {},
    'save_favorite_filter': {},
};

class Metrika {
    private eventData: MetrikaLogs<EventData> = DEFAULT_LOGS;

    public push<P extends keyof EventData>(eventName: P, data: MetrikaLogs<EventData>[P]) {
        this.eventData[eventName] = merge(this.eventData[eventName] || {}, data);
    }

    public reachGoal(eventName: EventType, params?: MetrikaParams) {
        const env = envSelector(store.getState());

        if (env === EnvTypes.dev) {
            console.warn(`Event ${eventName} was not sent, because env is development`);
            return;
        }

        ym('reachGoal', eventName, params);
        this.flush(eventName);
    }

    public flush(event: EventType) {
        if (this.eventData[event]) {
            this.eventData[event] = DEFAULT_LOGS[event];
        }
    }
}

const metrika = new Metrika();

export default metrika;
