import {UploadChangeParam} from 'antd/lib/upload';
import * as XLSX from 'xlsx';

import dates from 'utils/dates';
import i18n from 'utils/localization';

interface Options {
    download?: boolean | string;
    target?: '_self' | '_blank';
}

const parseExcelFile = (info: UploadChangeParam, pages: number[] = []) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    return new Promise<Record<string, unknown>>((resolve, reject) => {
        reader.onload = e => {
            const document = XLSX.read(e?.target?.result, {
                type: rABS ? 'binary' : 'array',
                bookVBA: true,
                // здесь важно форсить выбираемый формат, чтобы он был совместимым в parseFileDataIntoPlanningDate
                dateNF: dates.format.DATE_FOR_PARSE_EXCEL,
            });

            if (!document?.SheetNames?.length) {
                reject(new Error(i18n.print('common.import_errors.load_error')));
            }

            const sheets = document.SheetNames.reduce((acc: Record<string, unknown>, sheetName, index) => {
                if (pages.find(number => index === number) === undefined) {
                    return acc;
                }
                const sheet = document.Sheets[sheetName];
                let data;

                try {
                    data = XLSX.utils.sheet_to_json(sheet);
                } catch (e) {
                    reject(new Error(i18n.print('common.import_errors.parse_error')));
                }

                if (!data || !data.length) {
                    reject(new Error(i18n.print('common.import_errors.load_error')));
                }

                acc[index] = data;

                return acc;
            }, {});

            resolve(sheets);
        };

        const file = info.file.originFileObj || info.file;

        if (!file) {
            reject(new Error(i18n.print('common.import_errors.load_error')));
        }

        if (rABS) {
            reader.readAsBinaryString(file as Blob);
        } else {
            reader.readAsArrayBuffer(file as Blob);
        }
    });
};

export const downloadFile = (file: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
};

export const dataToCsvURI = (data: (string | undefined)[][], delimiter?: string): string => {
    const csvContent = data.map(row => row.join(delimiter || '\t')).join('\r\n');

    return encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
};

export function triggerLink(link: string, options: Options) {
    const finalOptions = Object.assign({target: '_self', download: false}, options);

    const a = document.createElement('a');

    a.href = link;
    a.target = finalOptions.target;

    if (finalOptions.download === true) {
        a.download = 'yes';
    } else if (typeof finalOptions.download === 'string') {
        a.download = finalOptions.download;
    }

    a.dispatchEvent(new MouseEvent('click'));
}

export default {
    parseExcelFile,
};
