import range from 'lodash/range';
import moment, {Moment} from 'moment-timezone';

import SettingsApi from 'api/app/settings';
import {ChangedRangeValue} from 'components/ui/DatePicker/types';
import i18n from 'utils/localization';

export const TIME_HMM = 'H:mm';
export const TIME_HHMMSS = 'HH:mm:ss';
export const TIME_HHMM = 'HH:mm';
export const TIME_HH = 'HH';
export const TIME_MM = 'mm';
export const DATE_D = 'D';
export const DATE_dddd = 'dddd';
export const DATE_MMM = 'MMM';
export const DATE_MMMM = 'MMMM';
export const DATE_D_MMM = 'D MMM';
export const DATE_DDMMYY = 'DD.MM.YY';
export const DATE_DDMMYYYY = 'DD.MM.YYYY';
export const DATE_DDMMYYYYHHMM = 'DD.MM.YYYY HH:mm';
export const DATE_DDMM = 'DD.MM';
export const DATE_ddd_DDMM = '(ddd) DD.MM';
export const DATETIME_DDMM_HH = 'DD.MM HH';
export const DATETIME_DDMM_HHMM = 'DD.MM HH:mm';
export const DATE_YYYYMMDD = 'YYYY-MM-DD';
export const DATE_DMMM = 'D MMM (ddd)';
export const DATE_MMMMYYYY = 'MMMM YYYY';
// решили, что это единственный правильный формат (источник файлов — MSOffice Excel)
// note: в интерфейсе Excel даты отображаются в формате DD.MM.YYYY, при парсинге xlsx приходят уже в формате MM/DD/YYYY (но я форшу DD/MM/YYYY для консистентности)
export const DATE_FOR_PARSE_EXCEL = 'DD/MM/YYYY';
export const DATETIME_YYYYMMDDHHMM = 'YYYY-MM-DD HH:mm';
export const DATETIME_YYYYMMDDTHHMMSS = 'YYYY-MM-DDTHH:mm:ss';
export const DATETIME_YYYYMMDDTHHMM = 'YYYY-MM-DDTHH:mm';
export const DATETIME_YYYYMMDDHH = 'YYYY-MM-DD HH';
export const DATETIME_DDMMHHMM = 'DD.MM(ddd) HH:mm';
export const DATETIME_DMMMHHMM = 'D MMM (ddd) HH:mm';
export const DATETIME_DDMMYYHHMM = 'DD.MM.YY HH:mm';
export const getTodayEnd = (date: Moment) => date?.clone()?.endOf('day');
export const getTodayEndFormatted = (date: Moment, format: string | undefined = undefined) => getTodayEnd(date)?.format(format);
export const getTodayStart = (date: Moment) => date?.clone()?.startOf('day');
export const getTodayStartFormatted = (date: Moment, format: string | undefined = undefined) => getTodayStart(date)?.format(format);
export const getTomorrowStart = (date: Moment) => date?.clone()?.add(1, 'day')?.startOf('day');
export const getTomorrowStartFormatted = (date: Moment, format: string | undefined = undefined) => getTomorrowStart(date)?.format(format);

export const HOURS_RANGE = range(0, 24);

/*
есть  проблема, связанная с тем, что нужно отправлять периоды графиков оператора в 0 таймзоне
https://st.yandex-team.ru/TEF-769#5fd1e49dfdbb837ab4d85f8c

если даты были переданы в DatePicker.RangePicker как UTC, то:

a) при выборе даты курсором значения, передаваемые в onChange выглядят так

       start                end
2020-11-28T00:00:00Z 2020-12-01T00:00:00Z

b) при заполнении даты (в данном случае, окончания) с клавиатуры значения, передаваемые в onChange выглядят так

       start                end
2020-11-28T00:00:00Z 2020-12-02T00:00:00+03:00 <- вот где проблема, т.е. если взять дату как

moment.utc('2020-12-02T00:00:00+03:00').format(), то это будет "2020-12-01T21:00:00Z"

чтобы работало корректно в обоих кейсах, нужен костыль в getTodayStartUTCFormatted

проблема описана здесь:

https://github.com/ant-design/ant-design/issues/11012
https://github.com/ant-design/ant-design/issues/17434
https://github.com/ant-design/ant-design/issues/15129

но не решена

*/
// см. коммент выше
export const getTodayStartUTCFormatted = (date: Moment, format: string | undefined = undefined) => getTodayStart(date).format(format).replace(/([+|-]\d{2}:\d{2})$/, 'Z');

export function convertLocalDateToServer(date: Undefinable<Moment>) {
    if (!date) {
        return undefined;
    }
    const currentTimezone = moment.tz(SettingsApi.getTimezone()).format('ZZ');

    return `${date.startOf('day').format(`${DATE_YYYYMMDD}T${TIME_HHMMSS}`)} ${currentTimezone}`;
}

export function convertDatetimeToServer(date: Moment): string;
export function convertDatetimeToServer(date: undefined): undefined;
export function convertDatetimeToServer(date: Undefinable<Moment>): Undefinable<string> {
    if (!date) {
        return undefined;
    }

    return date.format(`${DATE_YYYYMMDD}T${TIME_HHMMSS}Z`);
}

type DurationType = Parameters<Moment['diff']>[1];
const DURATION_TYPES_ACL: DurationType[] = ['seconds', 'minutes'];

export function makeMomentFromDuration(duration: number, type: DurationType = 'minutes'): Moment {
    if (!DURATION_TYPES_ACL.includes(type)) {
        throw new Error(i18n.print('common.error_duration_formatter'));
    }

    if (type === 'seconds') {
        return moment().startOf('day').seconds(duration);
    }

    return moment().startOf('day').minutes(duration);
}

type PeriodType = string | Moment;

export function checkPeriodStrict(start: PeriodType, end: PeriodType) {
    const datetimeDates = {
        from: moment(start),
        to: moment(end),
    };

    if (datetimeDates.from.isAfter(datetimeDates.to)) {
        throw new Error(i18n.print('components.shifts.the_dates_are_mixed_up.'));
    }

    return true;
}

export function makeFullPeriodWithTimes(value: ChangedRangeValue): ChangedRangeValue {
    const currentTimezone = moment.tz(SettingsApi.getTimezone()).format('Z');

    // iso шлёт в ручку время текущее пользователя, то есть выгрузка неверная бывает у смены
    return [
        (!!value?.[0]
            ? moment(
                `${value[0].startOf('day').format(DATE_YYYYMMDD)}T00:00:00${currentTimezone}`,
            )
            : value?.[0]) as Moment,
        (!!value?.[1]
            ? moment(
                `${value[1].endOf('day').format(DATE_YYYYMMDD)}T23:59:00${currentTimezone}`,
            )
            : value?.[1]) as Moment,
    ];
}

/*
* param1: minutes — minutes from start of day
* @return: HH:mm
* */
export function createTimeFromMinutes(minutes: number): string {
    return moment().startOf('day').add(minutes, 'minutes')
        .format('HH:mm');
}

type RangeType = [Moment, Moment];
/*
* @param {[Moment, Moment]} - first range
* @param {[Moment, Moment]} - second range. This range must intersect with lhs range
* @return {Boolean} - rhs intersect with lhs
* */
export function checkIntersect(lhs: RangeType, rhs: RangeType): boolean {
    if (
        rhs[0].isBetween(...lhs, 'days', '[]') ||
        rhs[1].isBetween(...lhs, 'days', '[]')
    ) {
        return true;
    }

    if (
        rhs[0].isSameOrBefore(lhs[0]) &&
        rhs[1].isSameOrAfter(lhs[0])
    ) {
        return true;
    }

    return false;
}

interface HoursListReturnType {
    last: number[];
    past: number[];
}

/*
* this function return list of last of the day hours
* for example, now 18:54,
* function will return [19, 20, 21, 22, 23] as last and [0, ..., 18] as past
* */
export function getHoursListFromNow(date: Moment): HoursListReturnType {
    const dateStart = moment(date).startOf('day');
    const diff = date.diff(dateStart);
    const duration = moment.duration(diff);

    const hours = duration.hours();
    // we don't need to circle hours array
    const rangeHour = hours === 23 ? 23 : hours + 1;

    return {
        past: range(0, rangeHour),
        last: range(rangeHour, 23),
    };
}

export function getFormattedPeriodFromDurationRange(from: Moment, to: Moment) {
    // eslint-disable-next-line max-len
    return `${from.format(TIME_HH)}${i18n.print('containers.schedules.tsch')} ${from.format(TIME_MM)}${i18n.print('common.minute_short')} - ${to.format(TIME_HH)}${i18n.print('containers.schedules.tsch')} ${to.format(TIME_MM)}${i18n.print('common.minute_short')}`;
}
