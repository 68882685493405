import ls from 'store';

import store from 'reduxStore';
import apiHostSelector from 'selectors/app/config/apiHostSelector';

type GetCSRFTokenResponseType = {
    'max-age-seconds': number;
    sk: string;
};

const CSRF_DATA_KEY = 'WFM_CSRF';
// Если до окончания действия токена осталось 5 или меньше минут, то мы заранее его обновим
// note: это копипаста из кц
// https://github.yandex-team.ru/taxi/callcenter-frontend/blob/02f814b9e92afd2c2432c8db692652f6e85dce9d/packages/callcenter-staff/utils/src/csrf.ts#L11
const UPDATE_TOKEN_TIMER = 5 * 60 * 1000;

const getRemoteCSRFToken = async () => {
    const host = apiHostSelector(store.getState());

    return fetch(`${host}csrf_token`, {credentials: 'include'})
        .then(resp => resp.json())
        .then(CSRFData => {
            CSRFData['max-age-seconds'] = Date.now() + CSRFData['max-age-seconds'] * 1000;

            ls.set(CSRF_DATA_KEY, CSRFData);

            return CSRFData.sk;
        });
};

const isLocalCSRFTokenValid = (validTo: number | null) => {
    return validTo && Number(validTo) - UPDATE_TOKEN_TIMER > Date.now();
};

const getLocalCSRFToken = () => {
    const CSRFData: GetCSRFTokenResponseType | undefined = ls.get(CSRF_DATA_KEY);

    if (CSRFData?.sk && isLocalCSRFTokenValid(CSRFData?.['max-age-seconds'])) {
        return CSRFData.sk;
    }
};

export const getCSRFToken = async (): Promise<string | undefined> => getLocalCSRFToken() || getRemoteCSRFToken();
