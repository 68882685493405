import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {DocumentationTypes} from 'components/Menu/Content/Footer/Settings/FooterMenu/DocumentationWrapper/Content/types';
import {EnvTypes} from 'reducers/app/types';
import envSelector from 'selectors/app/config/envSelector';
import {Languages} from 'types/languages';
import {CookieName, cookies} from 'utils/cookies';
import i18n from 'utils/localization';
import {HOST_PRODUCTION, HOST_TESTING} from 'utils/tariffEditor';

const language = cookies.get(CookieName.Language) || Languages.En;

const productPage = 'https://wiki.yandex-team.ru/wfm/';
const documentationPage = language === Languages.Ru
    ? 'https://wiki.yandex-team.ru/wfm/faq-po-wfm-effrat/'
    : 'https://wiki.yandex-team.ru/wfm/faq-po-wfm-effrat-en/';
const wfmDashboard = 'https://st.yandex-team.ru/dashboard/76275';

const RU_ONLY_ITEMS = [DocumentationTypes.ProductsPage, DocumentationTypes.DevelopIdeasPage];

export function useDocumentationItems() {
    const env = useSelector(envSelector);

    const items = useMemo(() => {
        const tariffEditorHost = env === EnvTypes.prod ? HOST_PRODUCTION : HOST_TESTING;
        const tariffEditorUrl = `https://${tariffEditorHost}/dev/configs/?group=wfm-frontend`;

        return [
            {
                key: DocumentationTypes.ProductsPage,
                label: i18n.print('components.menu.product_page'),
                url: productPage,
            },
            {
                key: DocumentationTypes.DocsPage,
                label: i18n.print('components.menu.go_to_documentation'),
                url: documentationPage,
            },
            {
                key: DocumentationTypes.DevelopIdeasPage,
                label: i18n.print('components.menu.development_ideas'),
                url: wfmDashboard,
            },
            {
                key: DocumentationTypes.ServiceConfigsPage,
                label: i18n.print('components.menu.service_configs'),
                url: tariffEditorUrl,
            },
        ].filter(({key}) => {
            if (language !== Languages.Ru) {
                return !RU_ONLY_ITEMS.includes(key);
            }
            return true;
        });
    }, [env]);

    return items;
}
