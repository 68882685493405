import {createSelector} from 'reselect';

import configLoadStatusSelector from './config/configLoadStatusSelector';
import {userLoadStatusSelector} from './user';

export default createSelector(
    configLoadStatusSelector,
    userLoadStatusSelector,
    (configLoadStatus, userLoadStatus) => {
        if (
            configLoadStatus === RequestStatusSuccessful &&
            userLoadStatus === RequestStatusSuccessful
        ) {
            return RequestStatusSuccessful;
        }

        if (configLoadStatus === RequestStatusFailed || userLoadStatus === RequestStatusFailed) {
            return RequestStatusFailed;
        }

        if (
            configLoadStatus === RequestStatusPending ||
            userLoadStatus === RequestStatusPending
        ) {
            return RequestStatusPending;
        }

        if (configLoadStatus === RequestStatusInitial && userLoadStatus === RequestStatusInitial) {
            return RequestStatusInitial;
        }
    },
);
