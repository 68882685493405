// eslint-disable-next-line
import {PATHS} from './consts';

import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import Absences from 'containers/Absences';
import ApprovalsAbsences from 'containers/ApprovalsAbsences';
import ApprovalsShifts from 'containers/ApprovalsShifts';
import Balancing from 'containers/Balancing';
import ClosedPeriod from 'containers/ClosedPeriod';
import DisciplineOverview from 'containers/DisciplineOverview';
import Employees from 'containers/Employees';
import NanimatorCandidates from 'containers/NanimatorCandidates';
import Predictions from 'containers/Predictions';
import ProjectActivities from 'containers/ProjectActivities';
import Reports from 'containers/Reports';
import Schedules from 'containers/Schedules';
import ShiftsOverview from 'containers/ShiftsOverview';
import ShiftsPlanning from 'containers/ShiftsPlanning';
import Skills from 'containers/Skills';
import Tags from 'containers/Tags';
import WorkBreaks from 'containers/WorkBreaks';

type ReactElements = Array<JSX.Element>;

const prepareRoutes = (fragments: ReactElements): ReactElements => {
    return fragments.reduce<ReactElements>((result, fragment) => {
        React.Children.forEach(fragment.props.children, subroute => {
            result.push(subroute);
        });

        return result;
    }, []);
};

const rawRoutes = [
    Absences,
    ApprovalsAbsences,
    ApprovalsShifts,
    Balancing,
    ClosedPeriod,
    // Debug,
    DisciplineOverview,
    Employees,
    NanimatorCandidates,
    Predictions,
    ProjectActivities,
    Reports,
    Schedules,
    ShiftsOverview,
    ShiftsPlanning,
    Skills,
    Tags,
    WorkBreaks,
    <React.Fragment key="other_routes">
        <Route path="/shifts" render={() => (
            <Redirect to={PATHS.SHIFTS.PLANNING}/>
        )}/>
        <Route path={PATHS.SHIFTS_PLANNING} render={() => (
            <Redirect to={PATHS.SHIFTS.PLANNING}/>
        )}/>
        <Route path="/" render={() => (
            <Redirect to={PATHS.SHIFTS.OVERVIEW}/>
        )}/>
    </React.Fragment>,
];

const index = prepareRoutes(rawRoutes);

export default (
    <>
        {/* eslint-disable-next-line react/no-children-prop */}
        <Switch children={index}/>
    </>
);
