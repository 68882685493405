// @ts-expect-error no typings library for https://github.com/clarketm/saga-monitor/issues/2
import createSagaMonitor from '@clarketm/saga-monitor/dist/saga-monitor.js';
import {createStore, applyMiddleware, compose, bindActionCreators, Middleware} from 'redux';
import {createLogger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducerFactory, {hasReducer} from 'reducers';
import {ExtensionType} from 'reducers/types';
import {createReduxHistory, routerMiddleware} from 'router';
import {hasDebugQueryParam} from 'utils/hasDebugQueryParam';
import {BindActionFn, BindActionTypedFn, CustomStore} from 'utils/redux/store/types';

const useDebugTools = (import.meta.env.DEV && !import.meta.env.VITE_TESTS) || hasDebugQueryParam();

export const sagaMiddleware = createSagaMiddleware(useDebugTools
    ? {
        sagaMonitor: createSagaMonitor({
            level: 'log',
            verbose: true,
            color: '#03A9F4',
            rootSagaStart: false,
            effectReject: false,
            effectCancel: false,
            actionDispatch: false,
            effectTrigger: false,
            effectResolve: false,
        }),
    }
    : undefined,
);

function createAppStore() {
    const middlewares: Middleware[] = [
        sagaMiddleware,
        routerMiddleware,
    ];

    if (useDebugTools && !import.meta.env.TESTS) {
        const logger = createLogger({collapsed: true});

        // TODO: Fix me when migrate to redux-toolkit
        middlewares.push(logger as Middleware);
    }

    // @ts-expect-error currently window may have redux_devtools object, will be replaced later
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const finalCreateStore = useDebugTools
        ? composeEnhancers(applyMiddleware(...middlewares))(createStore)
        : applyMiddleware(...middlewares)(createStore);

    return finalCreateStore(rootReducerFactory({}));
}

const appStore = createAppStore();

const recombineStoreWith = (extension: ExtensionType) => {
    // Нужно для избежания ошибки во время HMR
    if (Object.keys(extension).every(key => hasReducer(key))) {
        return;
    }

    appStore.replaceReducer(rootReducerFactory(extension));
    appStore.dispatch({type: '@@redux/INIT'});
};

const removeStore = (extension: ExtensionType) => {
    appStore.replaceReducer(rootReducerFactory(extension, true));
    appStore.dispatch({type: '@@redux/INIT'});
};

const bindActions: BindActionFn = <T extends Record<string, any>>(actions: T): T => {
    return Object.keys(actions).reduce((result, key) => {
        const subObj = actions[key];

        return {
            ...result,
            [key]: typeof subObj === 'function'
                ? bindActionCreators(subObj, appStore.dispatch)
                : bindActions(!!subObj ? subObj : {}),
        };
    }, {} as T);
};

const bindActionsTyped: BindActionTypedFn = <T extends object>(actions: T): T => {
    return bindActions(actions);
};

export const store: CustomStore = Object.assign(appStore, {
    recombineStoreWith,
    removeStore,
    bindActions,
    bindActionsTyped,
});

export const history = createReduxHistory(appStore);

export default store;
