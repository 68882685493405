import moment from 'moment-timezone';

import SettingsApi from 'api/app/settings';

export const nowMs = () => (new Date()).getTime();
export const now = () => Math.round(nowMs() / 1000);
export const currentOffsetMinutes = () => moment().utcOffset();
export const currentOffsetHours = () => currentOffsetMinutes() / 60;
export const currentOffsetHoursString = () => {
    const hours = currentOffsetHours();

    return `${hours < 10 ? '0' : ''}${hours}`;
};
export const localDateFromUtcNow = () => moment.utc().add(currentOffsetHours(), 'hours');
export const localDateFromUtc = (date: string, format: string | undefined = undefined) =>
    moment.utc(date, format).add(currentOffsetHours(), 'hours');

export const getTimezoneString = () => {
    const timezone = SettingsApi.getTimezone();
    const formatedTimezone = moment.tz(timezone).format('Z');

    return `${timezone} (${formatedTimezone})`;
};
