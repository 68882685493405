import {createSelector} from 'reselect';

import passportHostSelector from 'selectors/app/config/passportHostSelector';

export default createSelector(
    (state: GlobalStateType) => state.app.config.data?.apiHost,
    (state: GlobalStateType) => state.app.config.data?.internalApiHost,
    passportHostSelector,
    (apiHost, internalApiHost, {type}) => {
        if (type === 'internal') {
            return internalApiHost;
        }

        return apiHost;
    },
);
