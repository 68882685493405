export const PATHS = {
    SCHEDULES: '/schedules',
    BALANCING: '/balancing',
    EMPLOYEES: '/employees',
    REPORTS: {
        MAIN: '/reports',
        CHOICE: '/reports/choice',
        OPERATOR_STATISTIC: '/reports/operator-statistic',
    },
    SHIFTS_PLANNING: '/shifts-planning',
    SKILLS: '/skills',
    DEBUG: '/debug',
    ABSENCES: '/absences',
    PROJECT_ACTIVITIES: '/project-activities',
    WORK_BREAKS: '/work-breaks',
    ROLES: '/roles',
    PREDICTIONS: '/predictions',
    CLOSED_PERIOD: '/closed-period',
    TAGS: '/tags',
    NANIMATOR_CANDIDATES: {
        INDEX: '/nanimator-candidates',
    },
    SHIFTS: {
        INDEX: '/shifts',
        PLANNING: '/shifts/planning',
        OVERVIEW: '/shifts/overview',
    },
    DASHBOARD: {
        DISCIPLINE: '/dashboard/discipline',
    },
    APPROVALS: {
        SHIFTS: '/approvals/shifts',
        ABSENCES: '/approvals/absences',
    },
    // TODO: Выпилить
    DISCIPLINE: '/discipline',
    PLANNING: '/planning',
    OVERVIEW: '/overview',
};

export const CREATE = 'create';
