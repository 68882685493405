import {createSelector} from 'reselect';

export const metaSelector = createSelector(
    (state: GlobalStateType) => state.common.meta.item?.data,
    meta => {
        if (!meta) {
            return undefined;
        }

        // дешевле через спред убрать ненужное, чем тащить омит или мутировать мету
        const {blackbox: _, ...rest} = meta;

        return rest;
    },
);

export const blackboxSelector = (state: GlobalStateType) => state.common.meta.item?.data?.blackbox;

export const versionSelector = (state: GlobalStateType) => state.common.meta.item?.data?.version;
