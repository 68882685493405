import moment from 'moment-timezone';

import {ContainerType} from 'types/containers';
import {User} from 'types/user';

export interface PageState {
    id: ContainerType | null;
    isReady: boolean;
    isFetching: boolean;
    isFailed: boolean;
    accessGranted: boolean;
}

export const Locales = {
    RU: 'ru',
    EN: 'en',
    KA: 'ka',
    AZ: 'az',
} as const;

export type LocaleType = (typeof Locales)[keyof typeof Locales];

export const EnvTypes = {
    dev: 'development',
    prod: 'production',
} as const;

export type EnvType = (typeof EnvTypes)[keyof typeof EnvTypes];

export type ConfigResponseType = {
    env: EnvType;
    apiHost: string;
    internalApiHost: string;
    passportHost: string;
    yandexTeamPassportHost: string;
    fullcalendarToken: string;
};

export type ConfigState = RemoteEntityType & {
    data: Nullable<ConfigResponseType>;
};

type EvaluatedPermissionType = {
    rule_name: string;
    rule_value: string;
};

export type HandlerType = {
    method: string;
    path: string;
}

export type PredicateType = {
    init: {
        set: string[];
        arg_name: string;
        set_elem_type: string;
    };
    type: string;
}

export type RestrictionType = {
    handler: HandlerType;
    predicate: PredicateType;
}

export type UserResponseType = {
    evaluated_permissions: EvaluatedPermissionType[];
    permissions: string[];
    restrictions: RestrictionType[];
};

export type UserState = RemoteEntityType & {
    data: Nullable<User>;
};

export type SettingsState = {
    timezone: TimezoneType;
}

export type UIState = {
    globalSpinnerVisible: boolean;
}

export interface AppState {
    config: ConfigState;
    page: PageState;
    locale: LocaleType;
    user: UserState;
    settings: SettingsState;
    ui: UIState;
}

/*
* Все три таймзоны пока что останутся, чтобы дефолт сразу тащить отсюда.
* Полный список теперь берётся отсюда:
* https://tariff-editor-unstable.taxi.tst.yandex-team.ru/dev/configs/edit/WFM_FRONTEND_TIMEZONES?group=wfm-frontend
* */
export const Timezones = {
    MSK: 'Europe/Moscow',
    LOCAL: moment.tz.guess(),
    UTC: 'UTC',
} as const;

export type TimezoneType = (typeof Timezones)[keyof typeof Timezones];
