import store from 'reduxStore';
import passportHostSelector from 'selectors/app/config/passportHostSelector';

export const UpdateConditions = {
    SUCCESS_CONDITION: 'ok',
    ERROR_CONDITION: 'error',
} as const;

export type UpdateConditionsType = (typeof UpdateConditions)[keyof typeof UpdateConditions];

interface PassportResponse {
    status: UpdateConditionsType;
}

export default async (): Promise<PassportResponse> => {
    try {
        const {host} = passportHostSelector(store.getState());
        const response = await fetch(`${host}auth/update`, {
            credentials: 'include',
        });

        return await response.json();
    } catch (e) {
        return {
            status: UpdateConditions.ERROR_CONDITION,
        };
    }
};
