import {Menu as VendorMenu} from 'antd';
import xor from 'lodash/xor';
import React, {useMemo, useCallback, ReactText} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {useLocalStorage} from 'hooks/useLocalStorage';
import {userPermissionsSelector} from 'selectors/app/user';
import {useApprovalsDomains} from 'utils/queries/useApprovalsDomains';
import {useNanimatorDomains} from 'utils/queries/useNanimatorDomains';
import {useStaffAbsencesForDomains} from 'utils/queries/useStaffAbsencesForDomains';

import {
    makeMenuTree,
    MENU_STYLES,
} from './consts';
import {Props} from './types';

const checkRouteInPath = (path: string | null, route?: string): boolean => {
    const pathCategories = path ? path.split('/') : [];
    const routeCategories = route ? route.split('/') : [];

    if (pathCategories.length < routeCategories.length) {
        return false;
    }

    for (let i = 0; i < routeCategories.length; i++) {
        if (routeCategories[i] !== pathCategories[i]) {
            return false;
        }
    }

    return true;
};

const MenuTree: Props = () => {
    const {data: nanimatorDomains = []} = useNanimatorDomains();
    const {data: approvalsDomains = []} = useApprovalsDomains();
    const {data: domainsWithStaffAbsences = []} = useStaffAbsencesForDomains();
    const permissions = useSelector(userPermissionsSelector);

    const [openKeys, setOpenKeys] = useLocalStorage<string[]>('menu.open_keys', []);
    const {pathname} = useLocation();

    const handleOpenMenu = useCallback((items: ReactText[]) => {
        if (!items.length) {
            setOpenKeys([]);
            return;
        }

        setOpenKeys(xor(items.map(item => item.toString()), openKeys));
    }, [setOpenKeys, openKeys]);

    const menuTree = useMemo(() => {
        return makeMenuTree(nanimatorDomains, approvalsDomains, domainsWithStaffAbsences, permissions);
    }, [nanimatorDomains, approvalsDomains, domainsWithStaffAbsences, permissions]);
    const selectedKeys = useMemo(() => {
        return menuTree.reduce((keys: string[], item) => {
            if (!item) {
                return keys;
            }
            if (checkRouteInPath(pathname, item.key as string)) {
                keys.push(item.key as string);
            }

            if ('children' in item) {
                const subItem = item.children?.find(data => {
                    return checkRouteInPath(pathname, data?.key as string);
                });

                if (subItem) {
                    keys.push(subItem.key as string);
                }
            }

            return keys;
        }, []);
    }, [pathname, menuTree]);

    return (
        <VendorMenu
            theme="light"
            mode="inline"
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onOpenChange={handleOpenMenu}
            style={{
                ...MENU_STYLES,
            }}
            items={menuTree}
        />
    );
};

export default MenuTree;
