export enum Queries {
    Favorite = 'favorite',
    Id = 'id',
    Granularity = 'granularity',
    Domain = 'domain',
    CardEmployeeId = 'cardEmployeeId',
    NewCardEmployeeId = 'newCardEmployeeId',
    CardEmployeeTab = 'cardEmployeeTab',
    CardShiftId = 'cardShiftId',
    Fio = 'fio',
    Login = 'login',
    Supervisors = 'supervisors',
    Violations = 'violations',
    ProjectActivities = 'project_activities',
    Absences = 'absences',
    Organization = 'organization',
    TelegramLogin = 'telegram_login',
    ShowMode = 'showMode',
    Callcenters = 'callcenters',
    SortOrder = 'sortOrder',
    SortField = 'sortField',
    ShiftBeginFrom = 'shift_begin_from',
    ShiftBeginTo = 'shift_begin_to',
    UpdatedAtFrom = 'updated_at_from',
    UpdatedAtTo = 'updated_at_to',
    PeriodFrom = 'period_from',
    PeriodTo = 'period_to',
    ApprovalStatuses = 'approval_statuses',
    ApprovalAbsenceStatuses = 'approval_absence_statuses',
    ApprovalType = 'approval_type',
    EmploymentDate = 'employment_date',
    EmploymentDatePresetType = 'employment_date_preset_type',
    EmploymentDateFrom = 'employment_date_from',
    EmploymentDateTo = 'employment_date_to',
    Tags = 'tags',
    TagConnection = 'tagConnection',
    From = 'from',
    To = 'to',
    ScheduleTypes = 'schedule_types',
    Skill = 'skill',
    Skills = 'skills',
    SkillType = 'skill_type',
    DraftId = 'draftId',
    PeriodStart = 'periodStart',
    PeriodEnd = 'periodEnd',
    DatePeriodFrom = 'datePeriodFrom',
    DatePeriodTo = 'datePeriodTo',
    TargetSkill = 'targetSkill',
    Status = 'status',
    Statuses = 'statuses',
    Order = 'order',
    OrderBy = 'orderBy',
    FullName = 'full_name',
    ShiftType = 'shift_type',
    BreaksFrom = 'breaks_from',
    BreaksTo = 'breaks_to',
    PeriodRangeType = 'period_range_type',
    IsViolationsVisible = 'is_violations_visible',
    SettingsVisible = 'settingsVisible',
    WeekDays = 'weekDays',
}
