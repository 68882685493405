import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {YMInitializer} from 'react-yandex-metrika';

import {EnvTypes} from 'reducers/app/types';
import envSelector from 'selectors/app/config/envSelector';

import {getAccounts, OPTIONS, VERSION} from './consts';

const YandexMetrika: FC = () => {
    const env = useSelector(envSelector);

    if (env === EnvTypes.dev) {
        return null;
    }

    const accounts = getAccounts(env);

    return (
        <YMInitializer
            options={OPTIONS}
            accounts={accounts}
            version={VERSION}
        />
    );
};

export default YandexMetrika;
