import {useQuery} from '@tanstack/react-query';
import {notification} from 'antd';
import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';

import {VersionAPI} from 'api/version';
import {versionSelector} from 'selectors/common/meta';
import {MILLISECONDS_IN_HALF_HOUR} from 'utils/dates/consts';
import i18n from 'utils/localization';

export const useVersionWarning = () => {
    const {data} = useQuery({
        queryKey: ['version'],
        queryFn: async () => {
            const response = await VersionAPI.request();

            return response.data;
        },
        refetchInterval: MILLISECONDS_IN_HALF_HOUR,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        refetchIntervalInBackground: true,
    });

    const currentVersion = useSelector(versionSelector);

    const notificationShown = useRef(false);

    useEffect(() => {
        if (!data?.version || !currentVersion || notificationShown.current) {
            return;
        }

        if (data?.version !== currentVersion) {
            notification.warning({
                message: i18n.print('common.version.warning'),
                duration: 0,
            });
            notificationShown.current = true;
        }
    }, [data?.version, currentVersion]);
};
