import axios from 'axios';

import SettingsApi from 'api/app/settings';
import {ResourceType} from 'api/resources/types';
import {USER_URL} from 'api/user/consts';
import store from 'reduxStore';
import apiHostSelector from 'selectors/app/config/apiHostSelector';
import passportHostSelector from 'selectors/app/config/passportHostSelector';
import {CookieName, cookies} from 'utils/cookies';
import {delay} from 'utils/delay';
import {getDomain} from 'utils/domain';
import {findAndStopRumTimer} from 'utils/httpApi/callCenterApi/utils';
import {RumLogger} from 'utils/rumLogger';

import {getCSRFToken} from '../csrf';
import updatePassportCookie, {UpdateConditions} from '../updatePassportCookie';

import {RESOURCE_URL_MAP} from './consts';
import {AdvancedAxiosRequestConfigType, AdvancedAxiosResponseType} from './types';

const language = cookies.get(CookieName.Language);

export const callCenterApi = axios.create({
    baseURL: undefined, // see interceptor below
    withCredentials: true,
});

callCenterApi.interceptors.request.use(async function (config: AdvancedAxiosRequestConfigType) {
    config.headers['x-csrf-token'] = await getCSRFToken();
    config.headers['X-WFM-Domain'] = getDomain();
    config.headers['Accept-Language'] = language;
    config.headers['X-WFM-User-Timezone'] = config.headers['X-WFM-User-Timezone'] || SettingsApi.getTimezone();
    config.baseURL = apiHostSelector(store.getState());

    // Так как некоторые запросы могут содержать в качестве параметра домен
    const resourceKey = Object.keys(RESOURCE_URL_MAP).find(key => config.url?.includes(RESOURCE_URL_MAP[key as ResourceType])) as ResourceType || undefined;

    if (!!resourceKey && (config.method === 'get' || config.method === 'post')) {
        config.metadata = {timerKey: resourceKey};
        RumLogger.startTimer(resourceKey);
    }

    return config;
});

const redirectToPassport = () => {
    const SECONDS_DURATION = 3;

    setTimeout(() => {
        const {host, type} = passportHostSelector(store.getState())!;

        const url = type === 'internal' ? 'passport' : 'auth/welcome';

        window.location.replace(`${host}${url}?retpath=${window.location.href}`);
    }, SECONDS_DURATION * 1000);
};

const failedURLsCounts: Record<string, number> = {};
const PERMISSIONS_RETRIES_THRESHOLD = 1;
const NO_REDIRECT_PATHS = [
    '/v2/drafts/reject/',
    '/v1/operators/preprofiles/values',
];
let alreadyTriedToGetUser = false;

callCenterApi.interceptors.response.use(res => {
    return Promise.resolve(res);
}, error => {
    const AUTH_FAIL_STATUSES = [401, 403];

    // здесь была проблема с зацикливанием запроса: updatePasswordCookie может вернуть status: ok в ответе даже для
    // юзера, у которого нет прав к кц
    // для ручки user-info логика такая: user-info -> 401 -> updatePassportCookie -> status: ok -> user-info -> 401 -> редирект на паспорт
    if (error.config && AUTH_FAIL_STATUSES.includes(error.response.status)) {
        // Для некоторых ручек ответ 403 - норма, не нужно редиректить в паспорт
        if (NO_REDIRECT_PATHS.includes(error.config.url)) {
            return Promise.reject(error);
        }

        if (error.config.url === USER_URL) {
            if (alreadyTriedToGetUser) {
                redirectToPassport();

                return;
            }

            alreadyTriedToGetUser = true;
        }

        const failedRequestKey = `${error.config.method}${error.config.url}`;

        if (!failedURLsCounts.hasOwnProperty(failedRequestKey)) {
            failedURLsCounts[failedRequestKey] = 0;
        }

        if (failedURLsCounts[failedRequestKey] < PERMISSIONS_RETRIES_THRESHOLD) {
            updatePassportCookie()
                .then(updateStatusResponse => {
                    if (updateStatusResponse.status === UpdateConditions.SUCCESS_CONDITION) {
                        failedURLsCounts[failedRequestKey]++;

                        return callCenterApi.request(error.config);
                    } else {
                        redirectToPassport();
                    }
                });
        }
    }

    return Promise.reject(error);
});

const RETRIES_MAP: Record<string, number> = {};

callCenterApi.interceptors.response.use(res => {
    return Promise.resolve(res);
}, error => {
    const {url, method, data} = error.config;
    const {status} = error.response;
    // data здесь string, можем использовать как ключ
    const currentRetries = RETRIES_MAP[data] ? RETRIES_MAP[data] : 0;
    const STATUS_CODES_TO_RETRY = [429];
    const MAX_RETRIES = 3;

    if (STATUS_CODES_TO_RETRY.includes(status) && currentRetries < MAX_RETRIES) {
        RETRIES_MAP[data] = currentRetries + 1;
        return delay(2000)
            .then(() => callCenterApi({
                method,
                url,
                data,
            }));
    } else {
        delete RETRIES_MAP[data];
        return Promise.reject(error);
    }
});

callCenterApi.interceptors.response.use((res: AdvancedAxiosResponseType) => {
    findAndStopRumTimer(res?.config?.url, res.config.metadata?.timerKey);

    return Promise.resolve(res);
}, error => {
    findAndStopRumTimer(error?.config?.url, error.config?.metadata?.timerKey);

    return Promise.reject(error);
});
