import {useQuery} from '@tanstack/react-query';
import {notification} from 'antd';

import {ConfigsAPI} from 'api/configs';
import {WFMFrontendApprovalsDomains} from 'types/api/backend-py3/definitions/configs';
import {Domains} from 'types/domains';
import i18n from 'utils/localization';

export function useApprovalsDomains() {
    return useQuery({
        queryKey: ['resources.approvalsDomains'],

        queryFn: () => {
            return ConfigsAPI.request(['WFM_FRONTEND_APPROVALS_DOMAINS'])
                .then(response => {
                    if (!response.data.configs) {
                        return [];
                    }

                    const config = response.data.configs[0] as WFMFrontendApprovalsDomains;

                    return config.body as Domains[];
                })
                .catch(() => {
                    notification.error({
                        message: i18n.print('components.app.domains_loading_failed'),
                        description: i18n.print('components.employee.please_refresh_the_page'),
                    });

                    return [];
                });
        },
        gcTime: Infinity,
        refetchOnMount: false,
    });
}
